import { TimemanStatus } from "../bitrix/models/timemanEntity";
import { FormEvents, FormtimeEvent } from "../types/formtimeType";
import { CallEvent, CallEvents, CallTypes } from "../types/phoneType";
import { Report } from "../types/reportType";
import { BreakTypes, TimeEvent, TimeEvents } from "../types/timemanType";

export enum UserStatus {
  Free = "Libre",
  Form = "Formulario",
  Break = "Break",
  Lunch = "Lunch",
  Bathroom = "Bathroom",
  Desactivated = "Desactivado",
  InboundCall = "Atendiendo",
  OutboundCall = "Saliente",
  Loading = "Cargando...",
}

export const calculateStatus = (
  report: Report,
  timemanRecords: TimeEvent[] | undefined,
  timemanStatuses: TimemanStatus[] | undefined,
  formtimeRecords: FormtimeEvent[] | undefined,
  userId: number
): UserStatus => {
  // Report
  if (
    (report.calls as CallEvent[]).find(
      (c) =>
        c.event === CallEvents.Start &&
        c.data.USER_ID === userId.toString() &&
        [CallTypes.Inbound, CallTypes.InboundWithRedirection].includes(
          c.data.CALL_TYPE
        ) &&
        !c.callId.includes("AMexternalCall")
    )
  ) {
    return UserStatus.InboundCall;
  }
  if (
    (report.calls as CallEvent[]).find(
      (c) =>
        c.event === CallEvents.Start &&
        c.data.USER_ID === userId.toString() &&
        [CallTypes.Outbound, CallTypes.Callback].includes(c.data.CALL_TYPE) &&
        !c.callId.includes("AMexternalCall")
    )
  ) {
    return UserStatus.OutboundCall;
  }

  // Form
  const possibleFormRecord = formtimeRecords
    ?.sort((a, b) => b.ts - a.ts)
    .find((tr) => tr.data.userId === Number(userId));
  if (possibleFormRecord && possibleFormRecord.event === FormEvents.Started) {
    return UserStatus.Form;
  }

  // Timeman
  const possibleTimeRecord = timemanRecords
    ?.sort((a, b) => b.ts - a.ts)
    .find((tr) => tr.data.userIds.includes(userId));

  const possibleTimeStatus = timemanStatuses?.find(
    (ts) => ts.USER_ID === userId
  );

  if (possibleTimeRecord) {
    if (possibleTimeRecord.event === TimeEvents.Open) {
      return UserStatus.Free;
    } else if (possibleTimeRecord.event === TimeEvents.Pause) {
      if (possibleTimeRecord.data.breakType === BreakTypes.Lunch) {
        return UserStatus.Lunch;
      } else if (possibleTimeRecord.data.breakType === BreakTypes.Bathroom) {
        return UserStatus.Bathroom;
      } else {
        return UserStatus.Break;
      }
    } else {
      return UserStatus.Desactivated;
    }
  } else if (possibleTimeStatus) {
    if (possibleTimeStatus.STATUS === "OPENED") {
      return UserStatus.Free;
    } else if (possibleTimeStatus?.STATUS === "PAUSED") {
      return UserStatus.Break;
    } else {
      return UserStatus.Desactivated;
    }
  } else {
    return UserStatus.Loading;
  }
};
