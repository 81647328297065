import * as React from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Zoom from "@mui/material/Zoom";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import BathtubIcon from "@mui/icons-material/Bathtub";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import axiosInstance from "../../helpers/hostAxios";
import { useSnackbar } from "../../providers/SnackbarContext";
import { useMonitorContext } from "../../providers/MonitorContext";
import { calculateStatus, UserStatus } from "../../helpers/userStatus";
import { BreakTypes } from "../../types/timemanType";

export enum TimemanStatus {
  Open = "open",
  Pause = "pause",
  Close = "close",
}

interface FloatingActionButtonsProps {
  selectedRows: number[];
  setSelectedRows: React.Dispatch<React.SetStateAction<number[]>>;
}

export default function FloatingActionButtons({
  selectedRows,
  setSelectedRows,
}: FloatingActionButtonsProps) {
  const { report, timemanRecords, timemanStatuses, formtimeRecords } =
    useMonitorContext();
  const { openSnackbar } = useSnackbar();
  const [userStatuses, setUserStatuses] = React.useState<UserStatus[]>();

  const handleAction = (timeEvent: TimemanStatus, breakType?: BreakTypes) => {
    axiosInstance
      .post("/timeman/change", {
        userIds: selectedRows,
        timeEvent,
        breakType,
      })
      .then(() =>
        openSnackbar("Estado de los usuarios actualizado.", "success")
      );
    setSelectedRows([]);
  };

  const handleDisconnect = () => {
    axiosInstance
      .post("/phone/disconnect", {
        userIds: selectedRows,
      })
      .then(() =>
        openSnackbar("Estado de los usuarios actualizado.", "success")
      );
    setSelectedRows([]);
  };

  React.useEffect(() => {
    if (report) {
      const newUserStatuses: UserStatus[] = [];
      for (const userId of selectedRows) {
        newUserStatuses.push(
          calculateStatus(
            report,
            timemanRecords,
            timemanStatuses,
            formtimeRecords,
            userId
          )
        );
      }
      setUserStatuses(newUserStatuses);
    }
  }, [report, selectedRows, timemanRecords, timemanStatuses, formtimeRecords]);

  return (
    <Box
      sx={{
        "& > :not(style)": { m: 1 },
        position: "fixed",
        bottom: 24,
        right: 24,
      }}
    >
      <Zoom
        in={true}
        timeout={100}
        style={{
          transitionDelay: "100ms",
        }}
      >
        <Fab
          color="primary"
          variant="extended"
          onClick={() => handleAction(TimemanStatus.Open)}
          disabled={
            userStatuses?.includes(UserStatus.Free) ||
            userStatuses?.includes(UserStatus.Loading) ||
            userStatuses?.includes(UserStatus.InboundCall) ||
            userStatuses?.includes(UserStatus.OutboundCall)
          }
        >
          <PlayCircleIcon sx={{ mr: 1 }} />
          Activar
        </Fab>
      </Zoom>
      <Zoom
        in={true}
        timeout={100}
        style={{
          transitionDelay: "100ms",
        }}
      >
        <Fab
          variant="extended"
          onClick={() => handleAction(TimemanStatus.Pause, BreakTypes.Lunch)}
          disabled={
            userStatuses?.includes(UserStatus.Lunch) ||
            userStatuses?.includes(UserStatus.Desactivated) ||
            userStatuses?.includes(UserStatus.Loading) ||
            userStatuses?.includes(UserStatus.InboundCall) ||
            userStatuses?.includes(UserStatus.OutboundCall)
          }
        >
          <RestaurantIcon sx={{ mr: 1 }} />
          Almuerzo
        </Fab>
      </Zoom>
      <Zoom
        in={true}
        timeout={100}
        style={{
          transitionDelay: "100ms",
        }}
      >
        <Fab
          variant="extended"
          onClick={() => handleAction(TimemanStatus.Pause, BreakTypes.Break)}
          disabled={
            userStatuses?.includes(UserStatus.Break) ||
            userStatuses?.includes(UserStatus.Desactivated) ||
            userStatuses?.includes(UserStatus.Loading) ||
            userStatuses?.includes(UserStatus.InboundCall) ||
            userStatuses?.includes(UserStatus.OutboundCall)
          }
        >
          <PauseCircleIcon sx={{ mr: 1 }} />
          Break
        </Fab>
      </Zoom>
      <Zoom
        in={true}
        timeout={100}
        style={{
          transitionDelay: "100ms",
        }}
      >
        <Fab
          variant="extended"
          onClick={() => handleAction(TimemanStatus.Pause, BreakTypes.Bathroom)}
          disabled={
            userStatuses?.includes(UserStatus.Bathroom) ||
            userStatuses?.includes(UserStatus.Desactivated) ||
            userStatuses?.includes(UserStatus.Loading) ||
            userStatuses?.includes(UserStatus.InboundCall) ||
            userStatuses?.includes(UserStatus.OutboundCall)
          }
        >
          <BathtubIcon sx={{ mr: 1 }} />
          Baño
        </Fab>
      </Zoom>
      <Zoom
        in={true}
        timeout={100}
        style={{
          transitionDelay: "100ms",
        }}
      >
        <Fab
          color="error"
          variant="extended"
          onClick={() => handleAction(TimemanStatus.Close)}
          disabled={
            userStatuses?.includes(UserStatus.Desactivated) ||
            userStatuses?.includes(UserStatus.Loading) ||
            userStatuses?.includes(UserStatus.InboundCall) ||
            userStatuses?.includes(UserStatus.OutboundCall)
          }
        >
          <StopCircleIcon sx={{ mr: 1 }} />
          Desactivar
        </Fab>
      </Zoom>
      <Zoom
        in={true}
        timeout={100}
        style={{
          transitionDelay: "100ms",
        }}
      >
        <Fab
          color="error"
          variant="extended"
          onClick={handleDisconnect}
          disabled={
            userStatuses?.includes(UserStatus.Bathroom) ||
            userStatuses?.includes(UserStatus.Break) ||
            userStatuses?.includes(UserStatus.Desactivated) ||
            userStatuses?.includes(UserStatus.Form) ||
            userStatuses?.includes(UserStatus.Free) ||
            userStatuses?.includes(UserStatus.Loading) ||
            userStatuses?.includes(UserStatus.Lunch)
          }
        >
          <PhoneDisabledIcon sx={{ mr: 1 }} />
          Desconectar
        </Fab>
      </Zoom>
    </Box>
  );
}
